import React, { Component } from "react";
import { Formik, Form } from "formik";
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from "react-toggle-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faHistory } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import * as Utils from "../../../helpers/Utils";
import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";
import Initial from "./Tab/Initial";
import ExternalAnalysis from "./Tab/ExternalAnalysis";
import UnitAnalysis from "./Tab/UnitAnalysis";
import { Role, Status } from "../../../helpers/Constants";

import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { AnalysisService } from "../../../services/QualityReview/Analysis/AnalysisService";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import { HistoryService } from "../../../services/HistoryService";
import { authenticationService } from "../../../services/AuthService";

import { HistoryModal } from "./HistoryModal";
import { CommentModal } from "./CommentModal";

import { RejectModal } from "../../../components/Utils/Modal/RejectModal";
import { ConfirmationModal } from "../../../components/Utils/Modal/ConfirmationModal";

import { withTranslation } from "react-i18next";
import i18next from "i18next";
import * as Yup from 'yup';

export class DetailAnalysis extends Component {
  constructor(props) {
    super(props);

    const { id, typecase } = this.props.match.params;

    const detailType = this.props.location?.state?.formType
      ? this.props.location?.state?.formType
      : this.props.match.url.includes("follow-up") 
        ? "follow-up" 
        : "edit";

    this.state = {
      id: id,
      typecase: typecase,
      snackbaropen: false,
      snackbarmsg: "",
      backTab: "",
      nextTab: "external-analysis",
      historyForm: [],
      data: {},
      caseEntryData: [],
      inisiasiDisabled: false,
      externalDisabled: false,
      unitDisabled: false,
      comments: [],
      historys: [],
      historysCaseEntry: [],
      historydata: [],
      CommentModalShow: false,
      HistoryModalShow: false,
      ForwardedModal: false,
      ForwardedConfirmationModal: false,
      RejectedModal: false,
      RejectedConfirmationModal: false,
      versionOptions: [],
      isLastVersion: true,
      formtype: detailType,
      isSubmitted: true,
    };

    this.getAnalysisData(id);
    this.commentModalRef = React.createRef();
    this.historyList(id);    
    this.formikRef = React.createRef();
  }

  getAnalysisData(id) {
    AnalysisService.PrepareForm(id, this.state.typecase).then((result) => {
      if (result.data) {
        const caseEntryNavigation = result.data.aefiCaseEntryNavigation !== null
          ? result.data.aefiCaseEntryNavigation
          : result.data.aeCaseEntryNavigation;

        this.setState({
          data: result.data,
          causality_value: result.data.analysisProduct
            ? result.data.analysisProduct
            : result.data.trailAnalysisProduct,
          caseEntryData: caseEntryNavigation,
        });
      
        this.historyCaseEntryList(caseEntryNavigation?.id);

        var versionOptions = result.data.trailAnalysis
          ?.sort((a, b) => (a.followUp > b.followUp ? 1 : -1))
          .map((trail) => {
            return {
              value: trail.followUp === null ? 0 : trail.followUp,
              label:
                trail.followUp === 0 || trail.followUp === null
                  ? "Inisial"
                  : "Follow Up " + trail.followUp,
            };
          });
        versionOptions.push({
          value: result.data.followUp,
          label:
            result.data.followUp === 0
              ? "Inisial"
              : "Follow Up " + result.data.followUp,
        });

        if (versionOptions) {
          this.setState({
            versionOptions: versionOptions,
            selectedVersion: versionOptions[versionOptions.length - 1],
            lastVersion: versionOptions[versionOptions.length - 1],
            isLastVersion: true,
          });
        }

        let beforeVersion =
          this.state.selectedVersion?.value === 0
            ? 0
            : this.state.selectedVersion?.value - 1;
        AnalysisService.PrepareForm(
          id,
          this.state.typecase,
          beforeVersion
        ).then((result) => {
          if (result.data) {
            this.setState({
              historyData: result.data,
              history_causality_value: result.data.analysisProduct
                ? result.data.analysisProduct
                : result.data.trailAnalysisProduct,
              historyCaseEntryData:
                result.data.aefiCaseEntryNavigation !== null
                  ? result.data.aefiCaseEntryNavigation
                  : result.data.aeCaseEntryNavigation,
            });
          }
        });
      }
    });
    DropdownService.MasterList([
      "causalityAE",
      "causalityAEFIComplete",
      "causalityAEFIIncomplete",
      "analyzer",
      "causalityAEFIA",
      "causalityAEFIB",
      "causalityAEFIC",
      "causalityAEFID",
      "causalityAECertain",
      "causalityAEProbable",
      "causalityAEPossible",
      "causalityAEUnlikely",
      "causalityAEConditional",
      "causalityAEUnassessable",
      "seriousness",
      "expectedness",
      "outcome",
      "aefiClassification",
      "aefiClassificationCoincidental",
      "aefiClassificationProgrammaticError",
      "aefiClassificationUnknown",
      "aefiClassificationVaccineReaction",
      "aefiClassificationInjectionReaction",
    ]).then((result) => {
      this.setState({ list: result.data });
    });

    DropdownService.ProvinceList(62).then((result) => {
      this.setState({
        provinceList: result.data,
      });
    });
  }

  handleVersionChange(item) {
    this.setState(
      {
        selectedVersion: item,
        beforeVersion: item.value === 0 ? 0 : item.value - 1,
        isLastVersion: item === this.state.lastVersion ? true : false,
      },
      () => {
        const { id } = this.props.match.params;

        AnalysisService.PrepareForm(
          id,
          this.state.typecase,
          this.state.selectedVersion.value
        ).then((result) => {
          if (result.data) {
            this.setState({
              data: result.data,
              causality_value: result.data.analysisProduct
                ? result.data.analysisProduct
                : result.data.trailAnalysisProduct,
              caseEntryData:
                result.data.aefiCaseEntryNavigation !== null
                  ? result.data.aefiCaseEntryNavigation
                  : result.data.aeCaseEntryNavigation,
            });
          }
        });

        AnalysisService.PrepareForm(
          id,
          this.state.typecase,
          this.state.beforeVersion
        ).then((result) => {
          if (result.data) {
            this.setState({
              historyData: result.data,
              history_causality_value: result.data.analysisProduct
                ? result.data.analysisProduct
                : result.data.trailAnalysisProduct,
              historyCaseEntryData:
                result.data.aefiCaseEntryNavigation !== null
                  ? result.data.aefiCaseEntryNavigation
                  : result.data.aeCaseEntryNavigation,
            });              
          }            
        });          
      }
    );
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  handleTabChange(event, tab) {
    switch (tab) {
      case "#inisiasi":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          backTab: "",
          nextTab: "external-analysis",
          currentTab: "inisiasi",
        });
        break;
      case "#external-analysis":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: false,
          backTab: "inisiasi",
          nextTab: "unit-analysis",
          externalDisabled: false,
          currentTab: "external-analysis",
        });
        break;
      case "#unit-analysis":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          backTab: "external-analysis",
          nextTab: "",
          unitDisabled: false,
          currentTab: "unit-analysis",
        });
        break;
    }

    event.preventDefault();
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleNextClick(event) {
    document.getElementById("link-" + this.state.nextTab).click();
    event.preventDefault();
  }

  handleGetData(data) {
    this.setState({ caseEntryData: data });
  }

  handleCausality(data) {
    this.setState({ causality_value: data });
  }

  handleDisabledNext(tab, status) {
    switch (tab) {
      case "#inisiasi":
        this.setState({
          inisiasiDisabled: status,
        });
        break;
      case "#external-analysis":
        this.setState({
          externalDisabled: this.state.inisiasiDisabled ? true : status,
        });
        break;
      case "#unit-analysis":
        this.setState({
          unitDisabled: this.state.externalDisabled ? true : status,
        });
        break;
    }
  }

  handleSubmitClick(event) {
    if (!this.formikRef.current.isSubmitting) {
      this.formikRef.current.submitForm();
    }

    event.preventDefault();
  }

  moveBack() {
    this.props.history.push("/medical-review/analysis");
  }

  historyList(id) {
    HistoryService.ReadQualityReview(id).then((result) => {
      this.setState({ historys: result.data });
    });      
  }

  historyCaseEntryList(id) {       
    HistoryService.Read(id,'AEFI').then((result) => {
        this.setState({ historysCaseEntry: result.data });        
    });
  }

  render() {
    const { t } = this.props;

    const analysis = this.state.data;
    const causality = this.state.causality_value;
    const formType = this.state.formtype;
    const { isLastVersion } = this.state;
    const historyData = this.state.historyData ? this.state.historyData : [];
    const historyCausalty = this.state.history_causality_value
      ? this.state.history_causality_value
      : [];
    const historyCaseEntry = this.state.historyCaseEntryData
      ? this.state.historyCaseEntryData
      : [];    

    const currentUser = authenticationService.currentUserValue;
    const canStaffEdit =
      (currentUser.role === Role.Staff ||
        currentUser.role === Role.Manager) &&
      (analysis?.status === Status.Draft ||
        analysis?.status === Status.NotOk ||
        analysis?.status === Status.NotApproved ||
        (formType === "follow-up" && isLastVersion));
    const canUnitEdit =
      currentUser.role === Role.Unit &&
      (analysis?.status === Status.WaitingApproval ||
        analysis?.status === Status.NotApproved);
    const canDivisiEdit =
      currentUser.role === Role.Divisi &&
      (analysis?.status === Status.WaitingApproval ||
        analysis?.status === Status.Reviewed);

    const initial = {
      caseType:
        this.state.typecase === "aefi-incomplete" ||
        this.state.typecase === "aefi-complete"
          ? "KIPI"
          : "KTD",
      caseNumber: analysis?.caseNumber ? analysis?.caseNumber : "",
      relatedCaseNumber: analysis?.relatedCaseNumberJson
        ? analysis?.relatedCaseNumberJson?.map((item) => ({
            label: item,
            value: item
          }))
        : analysis?.relatedCaseNumber?.split(",")?.map((item) => ({
            label: item,
            value: item
          })),
      reportDate: Utils.getDate(analysis?.reportDate),
      surveyDate: Utils.getDate(analysis?.surveyDate),
      analysisDate: Utils.getDate(analysis?.analysisDate),
      completenessData: analysis?.completenessData === true ? "Yes" : "No",
      chronology: analysis?.chronology,
      analysisBy: analysis?.analysisBy,
      analysisByProvince: analysis?.analysisByProvince,
      uploadedFile: analysis?.uploadedFile,
      isSubmitted: true,
    };
    
    let HistoryModalShow = () => {
      this.setState({
        HistoryModalShow: true,
      });
    };

    let CommentModalShow = () => {
      this.commentModalRef.current.getComments(this.state.id);
      this.setState({
        CommentModalShow: true,
      });
    };

    let HistoryModalClose = () => {
      this.setState({
        HistoryModalShow: false,
      });
    };
    let CommentModalClose = () => {
      this.setState({
        CommentModalShow: false,
      });
    };

    const validationSchemaDict = {
      "external-analysis": Yup.object().shape({
          analysisBy: Yup.string().required("Required").nullable(),
      }),
  }


    return (
      <>
        <Snakbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={<span id="message-id">{this.state.snackbarmsg}</span>}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              {" "}
              X{" "}
            </IconButton>,
          ]}
        />
        <HistoryModal
          show={this.state.HistoryModalShow}
          onHide={HistoryModalClose}
          onShow={HistoryModalShow}
          historys={this.state.historys}
          historysCaseEntry={this.state.historysCaseEntry}
        />
        <CommentModal
          show={this.state.CommentModalShow}
          onHide={CommentModalClose}
          analysis={this.state.id}
          ref={this.commentModalRef}
          onShow={CommentModalShow}
          t={t}
        />

        {/* Forward Confirmation */}
        <ConfirmationModal
          show={this.state.ForwardedConfirmationModal}
          hide={() => {
            this.setState({ ForwardedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_unit")
              : t("confirmation_non_unit")
          }
          t={t}
        />

        {/* Forwarded */}
        <ConfirmationModal
          show={this.state.ForwardedModal}
          hide={() => {
            this.setState({ ForwardedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_success_unit")
              : t("confirmation_success_non_unit")
          }
          t={t}
        />

        {/* Rejected Confirmation */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedConfirmationModal}
          hide={() => {
            this.setState({ RejectedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_unit")
              : t("confirmation_reject_non_unit")
          }
          menu="medicalReview"
          t={t}
        />

        {/* Rejected */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedModal}
          hide={() => {
            this.setState({ RejectedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_success_unit")
              : t("confirmation_reject_success_non_unit")
          }
          menu="medicalReview"
          t={t}
        />
        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <div style={{ display: "grid" }}>
              <div className="nav-fitur">
                <div className="row form-select" style={{ float: "right" }}>
                  <Select
                    name="version"
                    options={this.state.versionOptions}
                    value={this.state.selectedVersion}
                    onChange={(item) => this.handleVersionChange(item)}
                    classNamePrefix="select"
                    className="basic-single"
                  />

                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ HistoryModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faHistory} />
                  </span>
                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ CommentModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </span>
                </div>
              </div>
              <ul className="nav nav-justified mb-3 menu-tab">
                <Tab
                  number="1"
                  showNumber={true}
                  title={t("initiation")}
                  href="#inisiasi"
                  onClick={(event) => this.handleTabChange(event, "#inisiasi")}
                  id="link-inisiasi"
                />
                <Tab
                  number="2"
                  showNumber={true}
                  title={t("analysis_by_external")}
                  href="#external-analysis"
                  onClick={(event) =>
                    this.handleTabChange(event, "#external-analysis")
                  }
                  id="link-external-analysis"
                  disabled={this.state.inisiasiDisabled}
                />
                <Tab
                  number="3"
                  showNumber={true}
                  title={t("analysis_by_pv")}
                  href="#unit-analysis"
                  onClick={(event) =>
                    this.handleTabChange(event, "#unit-analysis")
                  }
                  id="link-unit-analysis"
                  disabled={this.state.externalDisabled}
                />
              </ul>
            </div>
          </div>
        </div>
        <Formik
          innerRef={this.formikRef}
          enableReinitialize
          initialValues={initial}
          validationSchema={validationSchemaDict[this.state.currentTab]}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();

            const { id, typecase } = this.props.match.params;

            values.id = id;

            let causality = this.state.causality_value.filter(function (el) {
              return el != null;
            });

            if (
              values.caseType === "KIPI" &&
              values.completenessData === "Yes"
            ) {
              values.aefiCompleteRequests = causality;
              values.batch = causality;
            }

            if (
              values.caseType === "KIPI" &&
              values.completenessData === "No"
            ) {
              values.aefiIncompleteRequests = causality;
              values.batch = causality;
            }

            if (values.caseType === "KTD") {
              values.aeRequests = causality;
            }

            values.completenessData =
              values.completenessData === "No" ? false : true;
            values.caseEntry = this.state.caseEntryData.id;
            values.relatedCaseNumber = values.relatedCaseNumber
              ? values.relatedCaseNumber?.map((item) => item?.value)
              : [];
            if (formType !== "edit" && isLastVersion) {
              values.isFollowUp = true;
            }

            let data = values;
            if (values.caseType === "KIPI") {
              data.caseType = values.completenessData === false ? "aefi-incomplete" : "aefi-complete";
            } else {
                data.caseType = "ae";
            }
            
            var role = currentUser.role;

            AnalysisService.Update(data).then(
              (result) => {
                toastSuccess(t("success_edit_message"));
                this.props.history.push(`/medical-review/analysis`, {
                  pages: this.props?.location?.state?.page,
                });
              },
              (error) => {
                setSubmitting(false);
                toastError(error);
              }
            );
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content">
                {/* Inisiasi Tab */}

                <Initial
                  formik={formik}
                  page={this.props?.location?.state?.page}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  historyCausality={historyCausalty}
                  history={this.props.history}
                  historydata={historyData}
                  historyCaseEntry={historyCaseEntry}
                  caseEntry={this.state.caseEntryData}
                  updatedata={(data) => this.handleGetData(data)}
                  disabledNext={this.state.inisiasiDisabled}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  formType={formType}
                  isLastVersion={isLastVersion}
                  caseStatus={analysis?.status}
                  currentUser={currentUser}
                  t={t}
                />

                {/* External Tab */}

                <ExternalAnalysis
                  formik={formik}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  historyCausality={historyCausalty}
                  historydata={historyData}
                  historyCaseEntry={historyCaseEntry}
                  caseEntry={this.state.caseEntryData}
                  disabledNext={this.state.externalDisabled}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  handleCausality={(data) => this.handleCausality(data)}
                  causality={causality}
                  list={this.state.list}
                  provinceList={this.state.provinceList}
                  formType={formType}
                  isLastVersion={isLastVersion}
                  caseStatus={analysis?.status}
                  currentUser={currentUser}
                  t={t}
                  i18next={i18next}
                />

                {/* Unit Tab*/}

                <UnitAnalysis
                  formik={formik}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  historyCausality={historyCausalty}
                  historydata={historyData}
                  historyCaseEntry={historyCaseEntry}
                  caseEntry={this.state.caseEntryData}
                  disabledNext={this.state.unitDisabled}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  handleCausality={(data) => this.handleCausality(data)}
                  causality={causality}
                  list={this.state.list}
                  formType={formType}
                  isLastVersion={isLastVersion}
                  caseStatus={analysis?.status}
                  currentUser={currentUser}
                  t={t}
                  i18next={i18next}
                />
                  <input
                    name="isSubmitted"
                    type="hidden"
                    {...formik.getFieldProps("isSubmitted")}
                  />
                {this.state.nextTab == "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                     {/* Simpan sebagai draft*/}
                     {canStaffEdit && isLastVersion && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                           onClick={()=> { 
                            formik.setFieldValue("isSubmitted", false); 
                            formik.submitForm();
                         }}
                          text={t("save_as_draft")}
                          isSubmitting={
                            !this.state.isLastVersion || formik.isSubmitting
                          }
                        />
                      </ToggleDisplay>
                    )}
                    {/* Simpan */}
                    {canStaffEdit && isLastVersion && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          type="submit"
                          text={"Submit"}
                          isSubmitting={
                            !this.state.isLastVersion || formik.isSubmitting
                          }
                        />
                      </ToggleDisplay>
                    )}

                    <input
                      name="isApprove"
                      type="hidden"
                      {...formik.getFieldProps("isApprove")}
                    />

                    {/* Reject */}
                    {canDivisiEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          background="#e15756 0% 0% no-repeat padding-box"
                          text="Reject"
                          onClick={() => {
                            formik.setFieldValue("isApprove", false);
                            this.setState({ RejectedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {canUnitEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          background="#e15756 0% 0% no-repeat padding-box"
                          text="Reject"
                          onClick={() => {
                            formik.setFieldValue("isReview", false);
                            this.setState({ RejectedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {/* Forward/Approve */}
                    {canDivisiEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          text={
                            currentUser.role === Role.Unit
                              ? "Forward"
                              : "Approve"
                          }
                          onClick={() => {
                            formik.setFieldValue("isApprove", true);
                            this.setState({ ForwardedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {/* Forward/Approve */}
                    {canUnitEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          text={"Forward"}
                          onClick={() => {
                            formik.setFieldValue("isReview", true);
                            this.setState({ ForwardedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {formik.status && (
                      <div className={"alert alert-danger"}>
                        {formik.status}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailAnalysis);
