import React from 'react';
import { withTranslation } from "react-i18next";
import { toastSuccess } from '../../../components/Utils/Toast/Toast';

import { NotificationService } from '../../../services/Notification/NotificationService';

export class NotificationMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            page: 1,
            maxPage: 0,
            limit: 0,
            query: "",
            isRead: ""
        }

        this.refreshList()
    }    

    goToNotificationUrl(NotificationPurpose, caseNumber, purposeId) {
        var purpose = NotificationPurpose.toLowerCase()
        let url = ''
        var type = this.checkCaseType(caseNumber)        
        if (purpose.includes('caseentry')) {
            if (type == 'aefi') {
                url = `/case-entry/aefi/detail-aefi/` + purposeId;
            } else {
                url = `/case-entry/ae/detail-ae/` + purposeId;
            }
        } else if (purpose.includes('analysis')) {
            if (type == 'aefi') {
                url = `/medical-review/analysis/aefi-complete/detail/` + purposeId;
            } else {
                url = `/medical-review/analysis/ae/detail/` + purposeId;
            }
        } else if (purpose.includes('resume')) {
            url = `/medical-review/resume/detail/` + purposeId;
        } else if (purpose.includes('summary')) {
            url = `/medical-review/summary/detail/` + purposeId;
        } else if (purpose.includes('aefioverseas')) {
            url = `/report-submission/bpom/kipi-overseas/detail/` + purposeId;
        } else if (purpose.includes('bpomprogress')) {
            url = `/report-submission/bpom/progress/detail/` + purposeId;
        } else if (purpose.includes('manufacture')) {
            url = `/report-submission/manufacture/initial/detail/` + purposeId;
        } else if (purpose.includes('reporter')) {
            url = `/report-submission/reporter/detail/` + purposeId;
        } else if (purpose.includes('partner')) {
            url = `/report-submission/partner/detail/` + purposeId;
        } else if (purpose.includes('distribution')){ 
            url = `/product-distribution`;
        } else {
            url = `/report-submission/bpom`;
        }

        if (url != '') {
            this.props.history.push(url)
        } else {
            toastSuccess('Tidak memiliki detail data.')
        }        
    }

    checkCaseType(caseNumber) {        
        var substring = caseNumber?.substring(0,4).toLowerCase()
        if(substring == 'aefi'){
            return 'aefi'
        } else {
            return 'ae'
        }        
    }

    refreshList() {
        NotificationService.Read(this.state.page, this.state.limit, this.state.query, this.state.isRead ).then(result => {
            this.setState({
                records: result.data.records,
            });
        });
    }

    readNotification() {
        let allNotif = []

        this.state.records.map((item) => {
            if(item.isRead === false) {
                allNotif = [...allNotif, item.id]
            }
        })


        NotificationService.ReadNotification({"ids" : allNotif}).then(result => {
            this.setState({
                isEmpty: true,
            });
            this.refreshList()
        });
    }

    handleIconColor(icon) {
        let iconColor = '#C3F6C4';
        switch (icon) {
            case "new":
                iconColor = '#C3F6C4';
                break;
            case "approved":
                iconColor = '#00ABBD66';
                break;
            case "rejected":
                iconColor = '#FF8080';
                break;
            case "reviewed":
                iconColor = '#FFE680';
                break;
            case "low":
                iconColor = '#FFE680';
                break;
            case "normal":
                iconColor = '#C3F6C4';
                break;
            case "critical":
                iconColor = '#FF8080';
                break;
        }

        return iconColor;
    }

    render(){

        const { records } = this.state;       

        return (
            <>
                <div>
                    <div className="row">
                        <div className="col-12">
                            <p className="font-14" style={{float:"right", color:"#00ABBD", cursor:"pointer"}}  onClick={() => this.readNotification()}>Mark All as Read</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            {
                                records.map((item) => 
                                    <>
                                        <a 
                                            className="message-item d-flex align-items-center border-bottom px-3 py-2" onClick={() => this.goToNotificationUrl(item.purpose,item.caseNumber, item.purposeId)}
                                        >
                                            <div style={{width:"10%"}}>
                                                {
                                                    item.isRead === false && (
                                                        <div style={{width: 10, height: 10, background:this.handleIconColor(item.icon), borderRadius: "50%"}}/>
                                                    )
                                                }
                                            </div>
                                            <div className="btn rounded-circle btn-circle" style={{marginRight: 10, background: this.handleIconColor(item.icon)}}>
                                                <i className="fas fa-bell text-white"></i>
                                            </div>
                                            <div className="w-75 d-inline-block v-middle pl-2">
                                                <span className="font-12 text-nowrap d-block text-muted">
                                                    Case Number {item.caseNumber ? item.caseNumber : "-" }
                                                </span>
                                                <h6 className="message-title mb-0 mt-1">{item.message ? item.message : "-" }</h6>
                                            </div>
                                        </a>
                                    </>
                                )
                            }
                            {
                                records.length === 0 && (
                                    <div style={{textAlign:"center"}}>
                                        <p
                                            className="text-muted"
                                        >
                                            Tidak ada notifikasi baru
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withTranslation()(NotificationMenu);