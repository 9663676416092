import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import Modal from '../../components/Utils/Modal/Modal';
import ModalHeader from '../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../components/Utils/Modal/ModalBody';
import Button from '../../components/Utils/Button/Button';
import CreateableSelect2Multi from '../../components/Utils/Field/CreateableSelect2Multi';
import Input from '../../components/Utils/Field/Input';
import Textarea from '../../components/Utils/Field/Textarea';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';

import { AEFIService } from '../../services/CaseEntry/AEFIService';
import { AEService } from '../../services/CaseEntry/AEService';
import { FileService } from '../../services/FileService';
import * as Utils from '../../helpers/Utils';
import { AeCaseEntryMailPurpose, AefiCaseEntryMailPurpose } from '../../helpers/Constants';


export class EmailModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '', fileList: [], caseNumberGeneratedModal: {show: false, caseNumber: null} };
        this.fileRef = React.createRef();
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false, fileList: [] });
    }

    openfileDialog = () => {
        this.fileRef.current.click();
    }

    handleChange(selectorFiles) {
        // {"status":200,"data":{"contentType":"image/jpeg","fileExt":".jpg","fileName":"XWVRHOBURUNEMIQRPHQGJCTNNDLESUHH.jpg","url":"/api/v1/file/test/XWVRHOBURUNEMIQRPHQGJCTNNDLESUHH.jpg"},"message":"OK"}
        FileService.Upload(selectorFiles.files, 'email').then((success) => {
            if (success.status === 200) {
                this.setState({ fileList: [...this.state.fileList, success.data]})
                selectorFiles.value = null;
            }
        }, (error) => {
            console.log('error', error)
        })
    }

    handleFileRemove(index) {
        let data = this.state.fileList;
        if (data.length > 1) {
            data.splice(index, 1)
            this.setState({ fileList: data })
        } else {
            this.setState({ fileList: [] })
        }
    }

    render() {

        const {t} = this.props;

        const initialValuesFormik = {
            emailTo: [],
            emailCC: [],
            emailSubject: '',
            emailContent: '',
        }

        return (
            <div className="container">
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <Modal {...this.props} size="md">
                    <Formik
                        initialValues={initialValuesFormik}
                        validationSchema={
                            Yup.object().shape({
                                emailTo: Yup.array(Yup.string().email().required("Required")).required("Required"),
                                emailCC: Yup.array(Yup.string().email()),
                                emailSubject: Yup.string().required("Required"),
                                emailContent: Yup.string().required("Required")
                            })
                        }
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();

                            const objectData = {
                                "to": values.emailTo,
                                "cc": values.emailCC,
                                "subject": values.emailSubject,
                                "body": values.emailContent,
                                "files": this.state.fileList.map(dt => dt.fileName)
                            }

                            const callbackSuccess = () => {
                                this?.props?.onhide();
                                this.setState({ fileList: [] })
                                toastSuccess(`${
                                    this?.props?.values?.mailPurpose === "additional"
                                        ? t("mail_to_reporter")
                                        : this?.props?.values?.mailPurpose === "distribution"
                                        ? t("mail_to_distribution")
                                        : t("mail_to_quality_review")
                                } ${t('successfuly_send_email')} `);
                            }

                            const callbackError = (error) => {
                                setSubmitting(false);
                                toastError(error);
                            }

                            if (this.props.typecase === "KIPI") {
                                AEFIService.SendMail(this.props.id, {
                                    "mailRequest": objectData,
                                    "mailPurpose": `${AefiCaseEntryMailPurpose[this?.props?.values?.mailPurpose]} ${this?.props?.values?.caseNumber}`
                                }).then(
                                    (result) => {
                                        callbackSuccess();
                                    },
                                    (error) => {
                                        callbackError(error)
                                    })
                            }
    
                            if (this.props.typecase === "KTD") {
                                AEService.SendMail(this.props.id, {
                                    "mailRequest": objectData,
                                    "mailPurpose": `${AeCaseEntryMailPurpose[this?.props?.values?.mailPurpose]} ${this?.props?.values?.caseNumber}`
                                }).then(
                                    (result) => {
                                        callbackSuccess();
                                    },
                                    (error) => {
                                        callbackError(error)
                                    })
                            }
                        }}
                    >
                        {props => (
                            <Form>
                                {
                                    props.status &&
                                    <div className={'alert alert-danger'}>{props.status}</div>
                                }
                                <ModalContent>
                                    <div className="my-4">
                                        <h4 className="font-weight-bold">
                                            {this?.props?.values?.mailPurpose
                                                ? this?.props?.values?.mailPurpose === "additional"
                                                    ? t("mail_to_reporter")
                                                    : this?.props?.values?.mailPurpose === "distribution"
                                                    ? t("mail_to_distribution")
                                                    : t("mail_to_quality_review")
                                                : "Compose Mail"
                                            }
                                        </h4>
                                        <hr />
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-1">
                                                <label for="emailTo" className="form-label">To</label>
                                            </div>
                                            <div className="col-11 form-email">
                                                <div style={{width: "100%"}}>
                                                    <CreateableSelect2Multi
                                                        name="emailTo"
                                                        errors={props.errors}
                                                        touched={props.touched}
                                                        value={props.values.emailTo}
                                                        onChange={(name, value) => {
                                                            props.setFieldValue("emailTo", value);
                                                        }}
                                                        placeholder="To"
                                                        customCreateLabel={(inputValue) => `Send To ${inputValue}`}
                                                        onBlur={props.setFieldTouched}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className="form-grop">
                                        <div className="row">
                                            <div className="col-1">
                                                <label for="emailCC" className="form-label">CC</label>
                                            </div>
                                            <div className="col-11">
                                                <div>
                                                    <CreateableSelect2Multi
                                                        name="emailCC"
                                                        errors={props.errors}
                                                        touched={props.touched}
                                                        value={props.values.emailCC}
                                                        onChange={(name, value) => {
                                                            props.setFieldValue("emailCC", value);
                                                        }}
                                                        placeholder="CC"
                                                        customCreateLabel={(inputValue) => `CC To ${inputValue}`}
                                                        onBlur={props.setFieldTouched}/>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-2">
                                                <label for="emailSubject" className="form-label">Subject</label>
                                            </div>
                                            <div className="col-10">
                                                <div>
                                                    <Input
                                                        name="emailSubject"
                                                        type="text"
                                                        placeholder="Subject"
                                                        errors={props.errors}
                                                        touched={props.touched}
                                                        {...props.getFieldProps('emailSubject')} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <Textarea
                                                rows="6"
                                                name="emailContent"
                                                placeholder="Message"
                                                errors={props.errors}
                                                touched={props.touched}
                                                value={props.values.emailContent}
                                                {...props.getFieldProps('emailContent')} 
                                                disabled={this.state.isDisabled} 
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <input type="file" ref={this.fileRef} style={{display: "none"}} onChange={ (e) => this.handleChange(e.target) }/>

                                    <div className="form-group row">
                                        <div className='col-md-12 text-left card-lampiran'>
                                        {
                                            this.state.fileList?.length > 0 && (
                                                <span style={{ fontSize: 12, opacity: "0.5", marginBottom: 10 }}><i className="fas fa-paperclip" style={{ marginRight : 10}}></i> {this.state.fileList?.length} lampiran</span>
                                            )
                                        }
                                        {this.state.fileList.map((file, index) => {
                                            return ([
                                                <div class="input-group">
                                                    <input
                                                        className="form-control"
                                                        value={file.fileName} 
                                                        style={{marginBottom: 10, fontSize: 12, width:"80%"}}
                                                        readOnly
                                                    />
                                                    <div class="input-group-prepend" style={{marginBottom: 10, fontSize: 12}}>
                                                        <div class="input-group-text">
                                                            <FontAwesomeIcon icon={faTimes} onClick={() => this.handleFileRemove(index)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ])
                                        })}
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-2">
                                                    <IconButton
                                                        key="close"
                                                        arial-label="Close"
                                                        color="inherit"
                                                        onClick={this.openfileDialog}
                                                    >
                                                    <span style={{ fontSize: 16, opacity: "0.5"}}><i className="fas fa-paperclip"></i></span>
                                                    </IconButton>
                                                </div>
                                                <div className="col-10">
                                                    <div className="form-group" style={{float:"right"}}>
                                                        <div className="modal-button">
                                                            <Button
                                                                color="#495057"
                                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                                border="1px solid #495057"
                                                                text={t('cancel')}
                                                                onClick={() => {
                                                                    this.props.onhide();
                                                                    this.setState({ fileList: [] });
                                                                }}
                                                            />
                                                            <Button type="submit" text={t("send_email")} disabled={props.isSubmitting} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        )
    }
}